import React, { useEffect } from 'react'
import config from '../config';

export default function Banner() {
    useEffect(() => {
        // Dynamically load jQuery and other plugins
        const loadScript = (src) => {
          return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => resolve();
            script.onerror = () => reject();
            document.body.appendChild(script);
          });
        };
    
        const loadPlugins = async () => {
          try {
            await loadScript('plugins/jQuery/jquery.min.js');
            await loadScript('plugins/bootstrap/bootstrap.min.js');
            await loadScript('plugins/slick/slick.min.js');
            await loadScript('plugins/shuffle/shuffle.min.js');
            await loadScript('plugins/aos/aos.js');
            // Initialize AOS
            const AOS = window.AOS;
            if (AOS) {
              AOS.init();
            }
          } catch (error) {
            console.error('Error loading plugins:', error);
          }
        };
    
        loadPlugins();
      }, []);
    return (
        <section
            className="banner position-relative bg-cover-bottom has-shapes bg-light-gray has-bg-brash bg-brash-bottom position-relative"
            style={{ backgroundImage: "url('images/brushes/banner.svg')" }}
            data-aos="fade-in"
            data-aos-delay="150">
            <div className="container h-100">
                <div className="d-block d-lg-flex no-gutters align-items-center h-100">
                    <div className="col-12 col-xl-8 col-lg-8 order-lg-1">
                        <div className="banner-image has-shapes mb-4 mb-md-5 mb-lg-0">
                            <img className="img-fluid" src="images/screenshots/banner-shot.png" alt="" />
                            <div className="shape-lg bg-tertiary rounded-circle"></div>
                            <div className="shape-md bg-primary rounded-circle"></div>
                            <div className="shape-sm bg-secondary rounded-circle"></div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 order-lg-0" style={{ textAlign: "center" }}>
                        <h2 className="h2 mb-20"><strong>Play Big !! Win Big !!</strong></h2>
                        <div className="container">
                            <div className="row align-items-center justify-content-around">
                                <div className="col-lg-5 col-md-8 col-sm-10 mb-5">
                                    <div className="has-colored-text">
                                        {/** Counter Section */}
                                        <div className="d-inline-block mt-20 text-center" style={{ width: "90px" }}>
                                            <img src="images/players.png" className="mb-20" height="38px" />
                                            <h3 className="has-text-color font-weight-bold">
                                                <span className="jsCounter" data-count="5000">5000 </span> +
                                            </h3>
                                            <p style={{ fontSize: "12px" }}>Players</p>
                                        </div>
                                        <div className="d-inline-block mt-20 text-center" style={{ width: "90px" }}>
                                            <img src="images/games.png" className="mb-20" height="38px" />
                                            <h3 className="has-text-color font-weight-bold">
                                                <span className="jsCounter" data-count="25">25</span> +
                                            </h3>
                                            <p style={{ fontSize: "12px" }}>Games</p>
                                        </div>
                                        <div className="d-inline-block mt-20 text-center" style={{ width: "90px" }}>
                                            <img src="images/money.png" className="mb-20" height="38px" />
                                            <h3 className="has-text-color font-weight-bold">
                                                <span className="jsCounter" data-count="1">1</span> cr
                                            </h3>
                                            <p style={{ fontSize: "12px" }}>Daily Winnings</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <a href= {config.appDownloadPath} download>
                <button type="submit" className="btn btn-primary"
                    style={{
                        width: "calc(100% - 32px)",
                        color: "#666666",
                        marginLeft: "16px",
                        marginRight: "16px",
                        borderRadius: "25px",
                        padding: "10px 27px",
                        background: "linear-gradient(121.19deg,rgba(133,239,49,0) 25.73%,rgb(249, 232, 167) 45.27%,rgba(133,239,49,0) 62.27%),#fdedab",
                        animation: "shine 30s linear infinite"
                    }}
                >
                    <svg viewBox="64 64 896 896" focusable="false" data-icon="android" width="28px" height="28px"
                        fill="currentColor" style={{ marginBottom: "6px" }} aria-hidden="true">
                        <path d="M270.1 741.7c0 23.4 19.1 42.5 42.6 42.5h48.7v120.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V784.1h85v120.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V784.1h48.7c23.5 0 42.6-19.1 42.6-42.5V346.4h-486v395.3zm357.1-600.1l44.9-65c2.6-3.8 2-8.9-1.5-11.4-3.5-2.4-8.5-1.2-11.1 2.6l-46.6 67.6c-30.7-12.1-64.9-18.8-100.8-18.8-35.9 0-70.1 6.7-100.8 18.8l-46.6-67.5c-2.6-3.8-7.6-5.1-11.1-2.6-3.5 2.4-4.1 7.4-1.5 11.4l44.9 65c-71.4 33.2-121.4 96.1-127.8 169.6h486c-6.6-73.6-56.7-136.5-128-169.7zM409.5 244.1a26.9 26.9 0 1126.9-26.9 26.97 26.97 0 01-26.9 26.9zm208.4 0a26.9 26.9 0 1126.9-26.9 26.97 26.97 0 01-26.9 26.9zm223.4 100.7c-30.2 0-54.6 24.8-54.6 55.4v216.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V400.1c.1-30.6-24.3-55.3-54.6-55.3zm-658.6 0c-30.2 0-54.6 24.8-54.6 55.4v216.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V400.1c0-30.6-24.5-55.3-54.6-55.3z"></path>
                    </svg>
                    <strong style={{ fontSize: "x-large" }}>Download App</strong>
                </button>
            </a>

            <div className="container">
                <h2 className="h2 mb-20" style={{ textAlign: "center", marginTop: "20px" }}>
                    <strong>+917015447014</strong>
                </h2>
                <div className="container">
                    <div className="row align-items-center justify-content-around">
                        <div className="col-lg-5 col-md-8 col-sm-10 mb-5" style={{ textAlign: "center" }}>
                            <a href="tel:+917015447014">
                                <button style={{
                                    width: "130px", borderRadius: "20px",
                                    background: "linear-gradient(63deg,#f44336 0%, rgba(255,197,98,1) 100%)",
                                    border: "0px", color: "white", padding: "8px"
                                }}>
                                    <svg width="18" height="18" style={{ marginBottom: "4px" }}
                                        viewBox="0 0 24 24" id="call" xmlns="http://www.w3.org/2000/svg">
                                        <rect id="Rectangle_4" data-name="Rectangle 4" width="24" height="24" fill="none" />
                                        <path id="Shape" d="M7.02,15.976,5.746,13.381a.7.7,0,0,0-.579-.407L2.1,12.736A.728.728,0,0,0,1.4,13.2,10.468,10.468,0,0,0,4.24,20.319a10.506,10.506,0,0,0,7.113,3.738h.091a.733.733,0,0,0,.674-.7l.164-3.1a.739.739,0,0,0-.4-.6l-2.525-1.271a.717.717,0,0,0-.69.03l-2.2,1.236a6.269,6.269,0,0,1-1.273-.682A6.279,6.279,0,0,1,7.02,15.976Z"
                                            transform="translate(2.578 1.513)" fill="#fff" />
                                        <path id="Shape-2" data-name="Shape"
                                            d="M17.487,12.309h0A15.756,15.756,0,0,1,12.6,9.049a15.665,15.665,0,0,1-2.7-2.29,15.665,15.665,0,0,1-2.29-2.7,15.756,15.756,0,0,1-3.26-4.892.733.733,0,0,0-.69-.5h-.027l-3.4.164a.733.733,0,0,0-.7.674v.091a10.506,10.506,0,0,0,3.738,7.113A10.468,10.468,0,0,0,13.2,1.4a.728.728,0,0,0-.464-.7l-1.373-.531a.7.7,0,0,0-.579-.407l-2.594-.275a.7.7,0,0,0-.579.407L6.024,7.02a6.269,6.269,0,0,1-.682,1.273,6.279,6.279,0,0,1-1.273.682L1.4,13.2a.728.728,0,0,0,.464.7l1.373.531a.7.7,0,0,0,.579.407l2.594.275a.7.7,0,0,0,.579-.407L17.487,12.309Z"
                                            transform="translate(0 0)" fill="#fff" />
                                    </svg>
                                    Call Us
                                </button>
                            </a>
                            <a href="https://wa.me/+917015447014">
                                <button style={{ width: '130px', borderRadius: '20px', backgroundColor: '#25d366', border: '0px', color: 'white', padding: '8px' }}>
                                    <svg width="18" height="18" style={{ marginBottom: '4px' }} viewBox="-1.66 0 740.824 740.824" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" fill="white" clipRule="evenodd"
                                            d="M630.056 107.658C560.727 38.271 468.525.039 370.294 0 167.891 0 3.16 164.668 3.079 367.072c-.027 64.699 16.883 127.855 49.016 183.523L0 740.824l194.666-51.047c53.634 29.244 114.022 44.656 175.481 44.682h.151c202.382 0 367.128-164.689 367.21-367.094.039-98.088-38.121-190.32-107.452-259.707m-259.758 564.8h-.125c-54.766-.021-108.483-14.729-155.343-42.529l-11.146-6.613-115.516 30.293 30.834-112.592-7.258-11.543c-30.552-48.58-46.689-104.729-46.665-162.379C65.146 198.865 202.065 62 370.419 62c81.521.031 158.154 31.81 215.779 89.482s89.342 134.332 89.311 215.859c-.07 168.242-136.987 305.117-305.211 305.117m167.415-228.514c-9.176-4.591-54.286-26.782-62.697-29.843-8.41-3.061-14.526-4.591-20.644 4.592-6.116 9.182-23.7 29.843-29.054 35.964-5.351 6.122-10.703 6.888-19.879 2.296-9.175-4.591-38.739-14.276-73.786-45.526-27.275-24.32-45.691-54.36-51.043-63.542-5.352-9.183-.569-14.148 4.024-18.72 4.127-4.11 9.175-10.713 13.763-16.07 4.587-5.356 6.116-9.182 9.174-15.303 3.059-6.122 1.53-11.479-.764-16.07-2.294-4.591-20.643-49.739-28.29-68.104-7.447-17.886-15.012-15.466-20.644-15.746-5.346-.266-11.469-.323-17.585-.323-6.117 0-16.057 2.296-24.468 11.478-8.41 9.183-32.112 31.374-32.112 76.521s32.877 88.763 37.465 94.885c4.587 6.122 64.699 98.771 156.741 138.502 21.891 9.45 38.982 15.093 52.307 19.323 21.981 6.979 41.983 5.994 57.793 3.633 17.628-2.633 54.285-22.19 61.932-43.616 7.646-21.426 7.646-39.791 5.352-43.617-2.293-3.826-8.41-6.122-17.585-10.714" />
                                    </svg>&nbsp;Whatsapp
                                </button>
                            </a>
                            

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
