import React from 'react'
import Games from '../componets/Games'

export default function Charts() {
  return (
    <div>
        <Games/>
    </div>

  )
}
