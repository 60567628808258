import React from 'react'
import '../assets/css/sty.css';
import logo from '../assets/images/logo_header.png';
import config from '../config';
export default function Footer() {
  return (
    <div>
      {/* Start Footer */}
      <div
        className="bg-light-gray has-shapes has-bg-brash bg-brash-top"
        style={{ backgroundImage: "url('images/brushes/footer.svg')" }}
      >
        <div className="container">
          <div className="row pt-15 pb-15">
            <div className="col-lg-12 col-md-12" style={{ textAlign: "center" }}>
              <img className="mb-25 w-10" src={logo} alt="" />
            </div>
          </div>
          <div className="row pt-15 pb-15">
            <div
              className="col-lg-12 col-md-12"
              style={{ textAlign: "center", paddingBottom: "15px" }}
            >
              <img src="images/18plus.svg" height="42px" width="42px" alt="18+" />
            </div>
            <div className="col-lg-12 col-md-12" style={{ textAlign: "center" }}>
              Players need to be 18+ in order to register. Underage gambling is prohibited.
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 pt-20 pb-20" style={{ textAlign: "center" }}>
              <a href="abc.com">
                <img src="images/gambleaware.svg" height="72px" alt="Gamble Aware" />
              </a>
            </div>
            <div className="col-lg-6 col-md-6 pt-20 pb-20" style={{ textAlign: "center" }}>
              <a href="abc.com">
                <img src="images/gamblingtherapy.svg" height="72px" alt="Gambling Therapy" />
              </a>
            </div>
          </div>
          <div className="row pt-30 pb-30">
            <div className="col-lg-12 col-md-12" style={{ textAlign: "center" }}>
              <p>
                Our website is operated by {config.appTitle} International, a company established under the law of
                Isle of Man, with registered address at 1-10 Ballanoa Meadow IM4-2HT, Isle Of Man,
                and having its gaming sublicense issued by Isle of Man e-Gaming and all rights to
                operate the gaming software worldwide.
              </p>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="block border-top text-center content">
                  Copyright &copy; 2024 {config.appTitle}. All Rights Reserved
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Footer */}
    </div>
  )
}
