import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import config from '../config';

const faqs = [
    {
        id: 1,
        question: `What is ${config.appTitle}?`,
        answer: `${config.appTitle} is a Satta Matka gaming app where you can play games and win jackpots.`
    },
    {
        id: 2,
        question: `What games are available on ${config.appTitle}?`,
        answer: `Popular Satta Matka games like Kalyan, Sridevi, Milan, Time Bazar, Tara, and Rajdhani.`
    },
    {
        id: 3,
        question: "How can I start playing?",
        answer: `You can download the App and create your account to play the Games on ${config.appTitle}.`
    },
    {
        id: 4,
        question: "What is the minimum deposit and withdrawal amount?",
        answer: `The minimum deposit and withdrawal amount on ${config.appTitle} is ₹500.`
    },
    {
        id: 5,
        question: `Why choose ${config.appTitle} over other providers?`,
        answer: `${config.appTitle} is the most trusted gaming provider worldwide.`
    }
];

export default function FaqSection() {
    return (
        <section className="section-padding pt-0" data-aos="fade-up" data-aos-delay="150">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12 my-2 my-lg-0 mt-sm-0">
                        <div className="border-bottom"></div>
                    </div>
                </div>
                <div className="row justify-content-center no-gutters">
                    <div className="col-lg-12 mt-40">
                        <div className="accordion accordionFAQ mb-40" id="accordionFAQone">
                            <h2 className="text-black-200 mb-5 mt-4 text-center">
                                Frequently Asked <strong>Questions</strong>
                            </h2>
                            
                            {faqs.map((faq) => (
                                <div className="card border-0 border-bottom" key={faq.id}>
                                    <div className="card-header border-0 bg-transparent py-0" id={`FAQoneHeading${faq.id}`}>
                                        <h4 className="py-4" data-toggle="collapse" data-target={`#FAQoneCollapse${faq.id}`}
                                            aria-expanded="false" aria-controls={`FAQoneCollapse${faq.id}`}>
                                            <FontAwesomeIcon icon={faAngleDown}  style={{ marginRight: '10px' }}/>
                                            {faq.question}
                                        </h4>
                                    </div>
                                    <div id={`FAQoneCollapse${faq.id}`} className="collapse" aria-labelledby={`FAQoneHeading${faq.id}`}
                                        data-parent="#accordionFAQone">
                                        <div className="card-body pt-0 mb-10">
                                            <p>{faq.answer}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
