import React from 'react'

export default function GameRates() {
  return (
    <section
            id="rate"
            className="section-padding tools-use-section has-shapes bg-light-gray has-bg-brash bg-brash-y"
            style={{
                backgroundImage: 'url(images/brushes/section-top.svg), url(images/brushes/section-bottom.svg)',
            }}
        >
            <div className="container">
                <h2 className="h2 mb-20"><strong>Game Rates</strong></h2>
                <div className="row" data-aos="fade-up">
                    <div className="col-xl-4 col-md-6">
                        <div className="card rounded shadow border-0">
                            <div className="card-body p-15" style={{ textAlign: 'center' }}>
                                <div style={{ borderLeft: '4px solid #fab028' }}>
                                    <h3 className="font-weight-600">Single Digit</h3>
                                    <span className="h4 d-inline-flex">
                                        <h4>1 RS KA 9 Rs</h4>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="card rounded shadow border-0">
                            <div className="card-body p-15" style={{ textAlign: 'center' }}>
                                <div style={{ borderLeft: '4px solid #fab028' }}>
                                    <h3 className="font-weight-600">Jodi Digits</h3>
                                    <span className="h4 d-inline-flex">
                                        <h4>1 RS KA 850 Rs</h4>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="card rounded shadow border-0">
                            <div className="card-body p-15" style={{ textAlign: 'center' }}>
                                <div style={{ borderLeft: '4px solid #fab028' }}>
                                    <h3 className="font-weight-600">Patti</h3>
                                    <span className="h4 d-inline-flex">
                                        <h4>1 RS KA 100 Rs</h4>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="shape-1 shape-xs bg-secondary rounded-circle"></div>
            <div className="shape-2 shape-sm bg-primary rounded-circle d-none d-md-block"></div>
            <div className="shape-4 shape-xs bg-tertiary rounded-circle"></div>
            <div className="shape-5 shape-xs bg-tertiary rounded-circle"></div>
            <div className="shape-6 shape-md bg-secondary rounded-circle d-none d-md-block"></div>
        </section>
  )
}
