import './App.css';
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Header from './componets/Header';
import Footer from './componets/Footer';
import Home from './pages/Home';
import Charts from './pages/Charts';
import config from './config';

export function TestHeader() {
  return (
    <header style={{ backgroundColor: 'lightgray', padding: '20px' }}>
      <h1 style={{ color: 'black' }}>Header Test</h1>
    </header>
  );
}

function App() {
  useEffect(() => {
    document.title = config.appTitle;  // Set the application title
  }, []);

  return (
    <Router>
      <div>
        <Header />
        <main>
          <Routes>
            {/* Default route that redirects to Home */}
            <Route path="/" element={<Home />} />
            <Route path="/charts" element={<Charts />} />
            
            {/* Catch all unmatched paths and redirect to Home */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}
// function App() {
//   return (
//     <div className="App">
//       {/* <TestHeader /> */}
//       <Header />
//       <Banner/>
//       <WinnersCarousel/>
//       <GameRates/>
//       <Games/>
//       <FaqSection/>
//       <Footer/>
//       <Modals/>
//     </div>
//   );
// }

export default App;
