import React from 'react'
import Banner from '../componets/Banner'
import WinnersCarousel from '../componets/WinnersCarousel'
import GameRates from '../componets/GameRates'
import Games from '../componets/Games'
import FaqSection from '../componets/FaqSection'

export default function 
() {
  return (
    <div>
        <Banner/>
        <WinnersCarousel/>
        <GameRates/>
        <Games/>
        <FaqSection/>
    </div>
  )
}
