import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import logo from '../assets/images/logo_header.png';
import config from '../config';

export default function Header() {
    const [isNavOpen, setIsNavOpen] = useState(false);

    const handleToggle = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (
        <header className="header-nav position-relative bg-light-gray">
            <div className="container">
                <nav className="navbar navbar-expand-xl navbar-light px-0">
                    <Link className="navbar-brand p-0" to="/">
                        <img className="img-fluid" src={logo} alt="Logo" />
                    </Link>
                    {/* logo */}
                    <button
                        className="navbar-toggler bg-white rounded-0 p-0"
                        type="button"
                        onClick={handleToggle}
                        aria-controls="navlinks"
                        aria-expanded={isNavOpen}
                        aria-label="Toggle navigation"
                    >
                        <svg className={`nav-toggle-icon ${isNavOpen ? 'active' : ''}`} viewBox="0 0 100 100" width="40">
                            <path className="line top" d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20" />
                            <path className="line middle" d="m 70,50 h -40" />
                            <path className="line bottom" d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20" />
                        </svg>
                    </button>
                    {/* mobile-nav control button */}
                    <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navlinks">
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item active">
                                <Link className="nav-link" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/charts">Charts</Link>
                            </li>
                        </ul>
                        <div className="navbar-button">
                            <a href= {config.appDownloadPath} download>
                                <button className="btn btn-sm btn-outline-primary">Download App</button>
                            </a>
                        </div>
                    </div>
                    {/* nav links */}
                </nav>
            </div>
        </header>
    );
}
