import React, { useEffect, useState } from 'react';
import config from '../config';

export default function Games() {
    const [games, setGames] = useState([]); // State to hold the games data
    const [loading, setLoading] = useState(true); // State to manage loading status
    const [error, setError] = useState(null); // State to manage any errors

    useEffect(() => {
        // Fetch games from the API
        const fetchGames = async () => {
            try {
                const response = await fetch('https://indvote.in/KalyanMatka/webservices/games.php');
                const data = await response.json();
                
                // Map API data to the desired format
                const formattedGames = data.list.map(game => ({
                    name: game.name,
                    closeBidTime: game.close_time,
                    closeBidResultTime: game.close_result_time,
                    status: game.close_status ? 'Running For Now' : 'Closed for Today',
                    info: game.answer || '***-**-***', // Use answer or provide default info
                    downloadLink: config.appDownloadPath // Assuming the same download link for all
                }));

                setGames(formattedGames);
            } catch (err) {
                setError('Failed to fetch games.'); // Handle errors
                console.error(err);
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };

        fetchGames(); // Call the fetch function
    }, []); // Empty dependency array to run once on component mount

    if (loading) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Takes full screen height
                fontSize: '24px' // Increase the font size
            }}>
                Loading...
            </div>
        ); // Loading state
    }

    if (error) {
        return <div>{error}</div>; // Error state
    }

    return (
        <section
            id="timetable"
            className="section-padding tools-use-section has-shapes bg-light-gray has-bg-brash bg-brash-y"
            style={{ backgroundImage: 'url(images/brushes/section-top.svg), url(images/brushes/section-bottom.svg)' }}>
            <div className="container">
                <h2 className="h2 mb-20"><strong>Available Games</strong></h2>
                <div className="row" data-aos="fade-up">
                    {games.map((game, index) => (
                        <div className="col-xl-4 col-md-6" key={index}>
                            <div className="card rounded shadow border-0" style={{ marginBottom: '6px' }}>
                                <div className="card-body" style={{ padding: '15px 15px 6px 15px' }}>
                                    <div>
                                        <h3 className="font-weight-600 mb-10">
                                            <span>{game.name}</span>
                                            <span
                                                className="time"
                                                style={{ cursor: 'pointer' }}
                                                data-toggle="modal"
                                                data-target="#timeModal"
                                                data-name={game.info}
                                                data-closebidtime={game.closeBidTime}
                                                data-closebidresulttime={game.closeBidResultTime}
                                            >
                                                <img src="images/info.png" height="14px" width="14px" alt="info icon" />
                                            </span>
                                        </h3>
                                        <h4><span>{game.info}</span></h4>
                                        <div style={{ display: 'inline', position: 'absolute', top: '15px', right: '15px', textAlign: 'center' }}>
                                            <span style={{ fontSize: '12px' }} className={game.status.includes('Closed') ? 'red' : 'green'}>
                                                {game.status}
                                            </span><br />
                                            <a href={game.downloadLink} download style={{ marginTop: '14px' }}>
                                                <svg id="video" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 31.277 31.708">
                                                    <path id="Path_593" data-name="Path 593" d="M15.589,0A15.589,15.589,0,1,1,0,15.589,15.589,15.589,0,0,1,15.589,0Z" transform="translate(0.098 0)" fill="#fc0" />
                                                    <path id="Path_590" data-name="Path 590" d="M31.179,256H0a15.589,15.589,0,1,0,31.179,0Z" transform="translate(0 -239.882)" fill="#fab028" />
                                                    <g id="Group_1840" data-name="Group 1840" transform="translate(11.884 8.643)">
                                                        <g id="Group_1786" data-name="Group 1786" transform="translate(0 0)">
                                                            <g id="Group_1785" data-name="Group 1785">
                                                                <path id="Path_591" data-name="Path 591" d="M171.741,125.324a.741.741,0,0,1-.741-.741V111.741a.741.741,0,0,1,1.175-.6l8.89,6.421a.741.741,0,0,1,0,1.2l-8.89,6.421A.74.74,0,0,1,171.741,125.324Z" transform="translate(-171 -111)" fill="#fff" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_1787" data-name="Group 1787" transform="translate(0 7.162)">
                                                            <path id="Path_592" data-name="Path 592" d="M171,256v6.421a.741.741,0,0,0,1.175.6l8.89-6.421a.741.741,0,0,0,.307-.6Z" transform="translate(-171 -256)" fill="#fff" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </a><br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="shape-1 shape-xs bg-secondary rounded-circle"></div>
            <div className="shape-2 shape-sm bg-primary rounded-circle d-none d-md-block"></div>
            <div className="shape-4 shape-xs bg-tertiary rounded-circle"></div>
            <div className="shape-5 shape-xs bg-tertiary rounded-circle"></div>
            <div className="shape-6 shape-md bg-secondary rounded-circle d-none d-md-block"></div>
        </section>
    );
}
