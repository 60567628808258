import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function WinnersCarousel() {
    const winners = [
        { name: 'Ningaraj', prize: '₹ 12000/-' },
        { name: 'Ramvilas', prize: '₹ 32000/-' },
        { name: 'Abbas', prize: '₹ 11500/-' },
        { name: 'Gaurav Patel', prize: '₹ 66000/-' },
        { name: 'Anthony', prize: '₹ 78000/-' },
        { name: 'Komala', prize: '₹ 28000/-' },
        { name: 'Fatima', prize: '₹ 52500/-' }
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <section className="section-padding overflow-hidden">
            <div className="container">
                <h2 className="h2 mb-20"><strong>Today's Top Winners</strong>
                    <img
                        style={{ height: '32px', width: '32px', marginLeft: '16px' }}
                        src="images/winner.png" alt="winner"
                    />
                </h2>
                <div className="row">
                    <div className="col-md-12" data-aos="fade-up">
                        <Slider {...settings} className="brand-carousel shadow px-4 p-30">
                            {winners.map((winner, index) => (
                                <div key={index} className="brand-item d-flex align-items-center justify-content-center flex-column">
                                    <div>
                                        <p className="text-primary mb-10 text-capitalize" style={{ color: '#ff4901' }}>
                                            {winner.name}
                                        </p>
                                    </div>
                                    <div>
                                        <h3 className="text-black-200 font-weight-bold">{winner.prize}</h3>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
}
